import { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form/immutable";
import { DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogActions } from "@mui/material";
import { Button } from "@mui/material";
import { Delete } from "@mui/icons-material";

import * as EventDirectorActions from "../../../actions/eventDirector";

import { ResponsiveDialog } from "../../modal";
import { dateInput, required } from "../../forms/index";
import { LoadingButton } from "../../actions/loadingButton";
import { T } from "../../util/t";
import { AutoProgressButton } from "components/layout/forms/fields/AutoProgressButton";
import { timeFormat } from "../../../utils/dates";

export const EditBreakDialog = (props) => {
    useEffect(() => {
        const index = props.initialValues?.index;
        if (index != null && props.breaks && !props.breaks.get(index)) return props.handleClose();
    }, [props.breaks, props.initialValues]);

    return (
        <ResponsiveDialog className="edit-break-dialog"
                          open={props.open}
                          aria-labelledby="edit-break-dialog-title"
                          onClose={props.handleClose}>
            <BreakForm {...props}/>
        </ResponsiveDialog>
    );
};

@connect()
@reduxForm({ enableReinitialize: true, touchOnChange: true, form: "breakForm" })
class BreakForm extends Component {
    deleteBreak = () => {
        const { dispatch, eventId, initialValues, handleClose, afterDelete = () => {} } = this.props;

        return dispatch(EventDirectorActions.deleteBreak(eventId, initialValues.get("index")))
            .then(handleClose).then(afterDelete);
    };

    submit = (values) => {
        const { dispatch, eventId, handleClose, afterSubmit = () => {} } = this.props;
        return dispatch(EventDirectorActions.saveBreak(eventId, values))
            .then(handleClose).then(afterSubmit)
            .catch(e => {
                throw new SubmissionError({
                    date: (e.response && e.response.data && e.response.data.error) || "Oops, something went wrong!"
                });
            });
    };

    render = () => [
        <DialogTitle key="dialog-title" id="edit-break-dialog-title" className="sticky-dialog-title">
            <T>{this.props.adding ? "Add Break" : "Edit Break"}</T>
        </DialogTitle>,

        <DialogContent key="dialog-content" className="sticky-dialog-content">
            <form className="break-form" onSubmit={this.props.handleSubmit(this.submit)}>
                <Field name="date" component={dateInput}
                       label={<T>Date & restart time:</T>}
                       validate={required}
                       showTimeSelect
                       autoFocus
                       timeIntervals={5}
                       timeFormat={timeFormat()}
                       width="100%"
                       inline/>
            </form>
        </DialogContent>,

        <DialogActions key="dialog-actions" className="sticky-dialog-actions">
            {!this.props.adding &&
            <AutoProgressButton className="left" onClick={this.deleteBreak} startIcon={<Delete/>}>
                <T>Delete</T>
            </AutoProgressButton>}

            <Button variant="outlined" onClick={this.props.handleClose}>
                <T>Cancel</T>
            </Button>
            <LoadingButton variant="contained" action={this.props.handleSubmit(this.submit)} disabled={this.props.pristine && !this.props.adding} color="primary">
                <T>{this.props.adding ? "Add" : "Update"}</T>
            </LoadingButton>
        </DialogActions>
    ];
}
