import { Field } from "redux-form/immutable";
import { FormField, required, SearchSelectInput } from "../../../forms";
import { T } from "../../../util/t";
import { useLocationField } from "../new/hooks/useLocationField";
import { useRef } from "react";
import { formatTimezone } from "../../../../utils/utils";
import { Typography } from "@mui/material";
import { makeStyles } from "../../../providers/makeStyles";

const useLocationStyles = makeStyles({
    location: {
        ".formField": {
            marginBottom: 0
        }
    },
});

export const LocationInput = ({ name, formState, change, getValues }) => {
    const { error, loading, options, autocompleteProps } = useLocationField({ value: formState.get(name), setValue: value => change(name, value), getInitialValueLabel: value => value?.get("placePredictionText") });
    const requireLocation = useRef(value => required(value) ? error : undefined);
    const classes = useLocationStyles();
    const utcOffsetMinutes = formState.getIn([name,"utc_offset_minutes"]),
        timezone = utcOffsetMinutes && formatTimezone(utcOffsetMinutes);

    // README: does the error message changes if we use useRef? (in case of network error)

    return (window.location.search.includes("location") &&
            <FormField className={classes.location}>
                <Field name={name}
                       component={SearchSelectInput}
                       helpText="location_tooltip_text"
                       label={<T>Location:</T>}
                       validate={requireLocation.current}
                       loading={loading}
                       options={options}
                       autocompleteProps={autocompleteProps} />
                {timezone && <Typography variant="caption">
                    {timezone}
                </Typography>}
            </FormField>
    );
};
