import { useEffect, useRef, useState } from "react";
import { useError } from "hooks/useNotifications";
import { useCancellableDebounce } from "hooks/useCancellableDebounce";

export const API_KEY = "AIzaSyBQI1wnQnFEYziAp3aSL8-3Wt37YRAUXB8";

const setSessionToken = (AutocompleteSessionToken, sessionToken) => {
    if (!sessionToken) sessionToken = new AutocompleteSessionToken();
};

export const useLocationField = ({ value, setValue, getInitialValueLabel }) => {
    const places = useRef();
    const sessionToken = useRef();
    const pendingSearch = useRef();
    const { notifyError } = useError();
    const [error, setError] = useState("Enter a location for your event. You can use an address, a place of interest, or a city or region.");

    useEffect(() => {
        import(/* webpackChunkName: "@googlemaps/js-api-loader" */ "@googlemaps/js-api-loader").then(({ Loader }) => {
            new Loader({ apiKey: API_KEY, retries: 5 }).importLibrary("places").then(library => {
                places.current = library;
                if (pendingSearch.current) search(pendingSearch.current);
                setSessionToken(places.current.AutocompleteSessionToken, sessionToken.current);
            });
        });
    }, []);

    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(() => value ? [{ label: getInitialValueLabel(value), value }] : []);

    const search = async input => {
        setLoading(true);

        if (!places.current) return pendingSearch.current = input;

        const { AutocompleteSessionToken, AutocompleteSuggestion } = places.current;
        setSessionToken(AutocompleteSessionToken, sessionToken.current);
        setOptions([]);

        const request = {
            input,
            sessionToken: sessionToken.current,
            // locationBias: "IP_BIAS",
        };

        return AutocompleteSuggestion.fetchAutocompleteSuggestions(request).then(({ suggestions }) => {
            setLoading(false);
            setOptions(suggestions.map(({ placePrediction }) => ({ label: placePrediction.text.toString(), value: placePrediction })));
        }).catch(e => {
            notifyError("Oops, something went wrong!");
        });
    };
    const debouncedSearch = useCancellableDebounce(search, 400);

    // TODO
    // display alternative validation message when user types in a location and submits without selecting a location from the dropdown
    
    useEffect(() => {
        const isPlacePrediction = typeof value?.toPlace === "function";

        if (isPlacePrediction) {
            value.toPlace().fetchFields({
                fields: ["formattedAddress", "utcOffsetMinutes", "location", "googleMapsURI"],
            }).then(response => {
                const json = response.place.toJSON();
                const { googleMapsURI, ...rest } = json;
                setValue({ ...rest, googleMapsUri: googleMapsURI, placePredictionText: value.text.toString() });
                sessionToken.current = null; // clear session token after each session for billing purposes
            }).catch(error => {
                notifyError("Oops, something went wrong!");
                setError("Please select an option from the location list to fill in your location.");            
                setValue(null);
            });
        }
    }, [value]);

    return {
        loading,
        options,
        error,
        autocompleteProps: {
            onInputChange:  (e, value, reason) => {
                // README
                // might get away with skipping reset 'reason' otherwise prevent useEffect firing if first load is already a value
                return reason !== "selectOption" && (value ? debouncedSearch(value) : setValue(null));
            },
            blurOnSelect: true,
            freeSolo: true
        }
    };
};